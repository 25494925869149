/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import Layout from '../../components/Layout'
import ScannerContent from '../../components/ScannerContent'

const ScanResultsPage = () => (
  <Layout customSEO showCTA={false} showSecondaryCTA={false} showFooter={false} hideHeaderFooterNav>
    <ScannerContent />
  </Layout>
)

export default ScanResultsPage
