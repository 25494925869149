import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  border-radius: 16px;
  background-color: ${props => (props.color ? props.theme.color[props.color] : 'white')};
  padding: ${props => (props.smallCard ? '20px 24px' : props.largeCard ? '40px' : '28px')};
  width: 100%;
  height: 100%;
  ${props => props.border && `border: ${props.border}px solid ${props.theme.color.gray100};`}
  ${props => props.justifySpaceBetween && `display: flex; flex-direction: row; justify-content: space-between;`}
  ${props => props.boxShadow && `box-shadow: 0px 0px 14px 3px ${props.theme.color.dropShadow};`}
  ${props =>
    props.simpleCard &&
    `
    @media (max-width: 480px) {
      flex-direction: column;
      gap: 16px;
      align-items: center;
      * { text-align: center !important; }
    }
  `}
`

const ResultsCard = ({ children, color, border, justifySpaceBetween, smallCard, largeCard, boxShadow, ...props }) => (
  <Wrapper
    color={color}
    border={border}
    justifySpaceBetween={justifySpaceBetween}
    smallCard={smallCard}
    boxShadow={boxShadow}
    largeCard={largeCard}
    {...props}
  >
    {children}
  </Wrapper>
)

export default ResultsCard
