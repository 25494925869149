const generateScanResultsPageTranslations = ({ userLang }) => {
  const data = {
    en: {
      metaTitle: 'Scan URL',
    },
    de: {
      metaTitle: 'URL scannen',
    },
    fr: {
      metaTitle: "Scanner l'URL",
    },
    es: {
      metaTitle: 'URL de escaneo',
    },
    it: {
      metaTitle: 'Scansione URL',
    },
  }

  return data[userLang] || data.en
}

export default generateScanResultsPageTranslations
