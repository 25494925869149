export const buckets = {
  Layout: {
    name: {
      en: 'Layout',
      de: 'Layout',
      fr: 'Mise en page',
      es: 'Disposición',
      it: 'Disposizione',
    },
    description: {
      en: 'Defines the visual arrangement of elements on a webpage, providing structure and organization.',
      de: 'Definiert die visuelle Anordnung von Elementen auf einer Webseite und bietet Struktur und Organisation.',
      fr: 'Définit la disposition visuelle des éléments sur une page Web, fournissant structure et organisation.',
      es: 'Define la disposición visual de los elementos de una página web, proporcionando estructura y organización.',
      it: 'Definisce la disposizione visiva degli elementi su una pagina web, fornendo struttura e organizzazione.',
    },
    automated: ['1.3.1', '1.3.2', '1.4.4', '4.1.1'],
    expertAudit: ['1.3.1', '1.3.2', '1.3.5', '2.1.1', '2.4.2', '2.4.3', '2.4.6'],
  },
  Forms: {
    name: {
      en: 'Forms',
      de: 'Formulare',
      fr: 'Formulaires',
      es: 'Formularios',
      it: 'Forme',
    },
    description: {
      en: 'Elements for user input, vital for tasks like contact forms, sign-ups, and surveys.',
      de: 'Elemente für Benutzereingaben, wesentlich für Aufgaben wie Kontaktformulare, Anmeldungen und Umfragen.',
      fr: 'Éléments de saisie utilisateur, essentiels pour des tâches comme formulaires de contact, inscriptions et enquêtes.',
      es: 'Elementos para la entrada del usuario, vitales para tareas como formularios de contacto, registros y encuestas.',
      it: "Elementi per l'input dell'utente, vitali per attività come moduli di contatto, iscrizioni e sondaggi.",
    },
    automated: ['1.3.5', '3.3.2'],
    expertAudit: ['1.3.1', '1.3.5', '2.5.3', '3.3.2', '3.3.1', '3.3.2', '3.3.3', '3.3.4', '4.1.3'],
  },
  Labels: {
    name: {
      en: 'Labels',
      de: 'Labels',
      fr: 'Étiquettes',
      es: 'Etiquetas',
      it: 'Etichette',
    },
    description: {
      en: 'Clear, descriptive text on form fields and other interactive elements provides guidance and context for people with visual and cognitive impairments.',
      de: 'Klarer, beschreibender Text auf Formularfeldern und anderen interaktiven Elementen bietet Anleitung und Kontext für Menschen mit visuellen und kognitiven Beeinträchtigungen.',
      fr: "Un texte clair et descriptif sur les champs de formulaire et d'autres éléments interactifs donne direction et contexte aux personnes ayant une déficience visuelle et cognitive.",
      es: 'El texto claro y descriptivo en los campos del formulario y otros elementos interactivos proporciona orientación y contexto para las personas con discapacidades visuales y cognitivas.',
      it: 'Un testo chiaro e descrittivo sui campi del modulo e su altri elementi interattivi fornisce guida e contesto per le persone con disabilità visive e cognitive.',
    },
    automated: ['4.1.2', '2.5.3', '1.1.1', '2.4.4'],
    expertAudit: ['2.1.4', '1.4.13'],
  },
  Navigation: {
    name: {
      en: 'Navigation',
      de: 'Navigation',
      fr: 'La navigation',
      es: 'Navegación',
      it: 'Navigazione',
    },
    description: {
      en: 'Menus, search bars, and links enable users to navigate between pages or sections of a website.',
      de: 'Menüs, Suchleisten und Links ermöglichen es Benutzern, zwischen Seiten oder Abschnitten einer Website zu navigieren.',
      fr: "Les menus, les barres de recherche et les liens permettent aux utilisateurs de naviguer entre les pages ou les parties d'un site Web.",
      es: 'Los menús, barras de búsqueda y enlaces permiten a los usuarios navegar entre páginas o secciones de un sitio web.',
      it: 'Menu, barre di ricerca e collegamenti consentono agli utenti di navigare tra le pagine o le sezioni di un sito web.',
    },
    automated: ['2.4.3', '2.4.1'],
    expertAudit: [
      '1.1.1',
      '1.3.1',
      '1.4.1',
      '1.4.13',
      '2.1.1',
      '2.1.2',
      '2.4.1',
      '2.4.2',
      '2.4.3',
      '2.4.4',
      '2.4.8',
      '2.4.9',
      '3.2.4',
      '4.1.1',
      '4.1.2',
    ],
  },
  Links: {
    name: {
      en: 'Links',
      de: 'Links',
      fr: 'Liens',
      es: 'Enlaces',
      it: 'Collegamenti',
    },
    description: {
      en: 'Clear, descriptive links help people with some visual and cognitive impairments navigate between webpages.',
      de: 'Klare, beschreibende Links helfen Menschen, mit einigen visuellen und kognitiven Beeinträchtigungen, zwischen Webseiten zu navigieren.',
      fr: 'Des liens clairs et descriptifs aident les personnes souffrant de certaines déficiences visuelles et cognitives à naviguer entre les pages Web.',
      es: 'Los enlaces claros y descriptivos ayudan a las personas con algunas discapacidades visuales y cognitivas a navegar entre páginas web.',
      it: 'Collegamenti chiari e descrittivi aiutano le persone con alcune disabilità visive e cognitive a navigare tra le pagine web.',
    },
    automated: ['1.3.1', '2.4.4', '2.4.9'],
    expertAudit: [
      '1.1.1',
      '1.3.1',
      '1.4.1',
      '1.4.13',
      '2.1.1',
      '2.1.2',
      '2.4.1',
      '2.4.4',
      '2.4.8',
      '2.4.9',
      '4.1.1',
      '4.1.2',
    ],
  },
  Headings: {
    name: {
      en: 'Headings',
      de: 'Überschriften',
      fr: 'En-têtes',
      es: 'El membrete',
      it: 'Intestazioni',
    },
    description: {
      en: 'Clear, descriptive headings help all users browse webpages and navigate to the most relevant content.',
      de: 'Klare, beschreibende Überschriften helfen allen Benutzern, Webseiten zu durchsuchen und zu dem relevantesten Inhalt zu navigieren.',
      fr: 'Des en-têtes clairs et descriptifs aident tous les utilisateurs à parcourir les pages Web et à accéder au contenu le plus pertinent.',
      es: 'Los títulos claros y descriptivos ayudan a todos los usuarios a navegar por las páginas web y acceder al contenido más relevante.',
      it: 'Titoli chiari e descrittivi aiutano tutti gli utenti a navigare nelle pagine Web e a raggiungere i contenuti più pertinenti.',
    },
    automated: ['2.4.6'],
    expertAudit: ['1.3.1', '2.4.2', '2.4.6'],
  },
  Content: {
    name: {
      en: 'Content',
      de: 'Inhalt',
      fr: 'Contenu',
      es: 'Contenido',
      it: 'Contenuto',
    },
    description: {
      en: 'Encompasses all information, text, images, and multimedia, providing value to visitors.',
      de: 'Umfasst alle Informationen, Texte, Bilder und Multimediainhalte, die den Besuchern einen Wert bieten.',
      fr: 'Toutes les informations, textes, images et multimédias apportant de la valeur aux visiteurs.',
      es: 'Abarca toda la información, texto, imágenes y multimedia, aportando valor a los visitantes.',
      it: 'Comprende tutte le informazioni, testi, immagini e contenuti multimediali, fornendo valore ai visitatori.',
    },
    automated: ['3.1.1', '4.1.1', '3.1.2'],
    expertAudit: [
      '1.1.1',
      '1.3.1',
      '1.3.2',
      '1.3.4',
      '1.3.5',
      '1.4.12',
      '2.1.1',
      '2.2.2',
      '2.4.1',
      '2.4.3',
      '2.4.6',
      '2.5.3',
      '3.1.1',
      '3.2.4',
      '3.3.1',
      '3.3.2',
      '3.3.3',
      '3.3.4',
      '4.1.1',
      '4.1.2',
      '4.1.3',
    ],
  },
  'Colors and Contrast': {
    name: {
      en: 'Colors & Contrast',
      de: 'Farben und Kontrast',
      fr: 'Couleurs et contraste',
      es: 'Colores y contraste',
      it: 'Colori e contrasto',
    },
    description: {
      en: 'Critical for visual design, impacting readability and accessibility for people with low vision or color blindness.',
      de: 'Entscheidend für visuelles Design, beeinflusst Lesbarkeit und Zugänglichkeit für Menschen mit Sehbehinderung oder Farbenblindheit.',
      fr: 'Indispensable pour la conception visuelle, ayant un impact sur la lisibilité et l’accessibilité pour les personnes malvoyantes ou daltoniennes.',
      es: 'Es fundamental para el diseño visual, lo que afecta la legibilidad y la accesibilidad para personas con baja visión o daltonismo.',
      it: "Fondamentale per la progettazione visiva, in quanto influisce sulla leggibilità e sull'accessibilità per le persone ipovedenti o daltoniche.",
    },
    automated: ['1.4.1'],
    expertAudit: [],
  },
  Interactive: {
    name: {
      en: 'Interactive',
      de: 'Interaktiv',
      fr: 'Interactif',
      es: 'Interactivo',
      it: 'Interattivo',
    },
    description: {
      en: 'Buttons, forms, and other interactive elements enable users to perform essential tasks on a website, such as making a purchase or submitting information.',
      de: 'Schaltflächen, Formulare und andere interaktive Elemente ermöglichen es Benutzern, auf einer Website wichtige Aufgaben auszuführen, beispielsweise einen Kauf zu tätigen oder Informationen zu übermitteln.',
      fr: "Les boutons, formulaires et autres éléments interactifs permettent aux utilisateurs d'effectuer des tâches essentielles sur un site Web, comme effectuer un achat ou soumettre des informations.",
      es: 'Los botones, formularios y otros elementos interactivos permiten a los usuarios realizar tareas esenciales en un sitio web, como realizar una compra o enviar información.',
      it: 'Pulsanti, moduli e altri elementi interattivi consentono agli utenti di eseguire attività essenziali su un sito Web, come effettuare un acquisto o inviare informazioni.',
    },
    automated: ['4.1.2', '2.2.1'],
    expertAudit: [
      '1.1.1',
      '1.2.1',
      '1.2.2',
      '1.2.3',
      '1.2.4',
      '1.2.5',
      '1.3.1',
      '1.3.2',
      '1.3.5',
      '1.4.13',
      '2.1.1',
      '2.1.2',
      '2.1.4',
      '2.2.2',
      '2.4.1',
      '2.4.3',
      '2.4.6',
      '2.5.3',
      '3.2.1',
      '3.2.4',
      '3.3.1',
      '3.3.2',
      '3.3.3',
      '3.3.4',
      '4.1.2',
      '4.1.3',
    ],
  },
  'Flashing Media': {
    name: {
      en: 'Flashing Media',
      de: 'Blinkende Medien',
      fr: 'Média clignotant',
      es: 'Medios intermitentes',
      it: 'Supporti lampeggianti',
    },
    description: {
      en: 'Flashing media — such as animated banners — can pose a risk to users with photosensitive seizure disorders.',
      de: 'Blinkende Medien — wie animierte Banner — können für Benutzer mit lichtempfindlichen Anfallsleiden ein Risiko darstellen.',
      fr: 'Les médias clignotants, tels que les bannières animées, peuvent présenter un risque pour les utilisateurs souffrant de troubles épileptiques photosensibles.',
      es: 'Los medios parpadeantes, como los banners animados, pueden representar un riesgo para los usuarios con trastornos convulsivos fotosensibles.',
      it: 'I contenuti multimediali lampeggianti, come i banner animati, possono rappresentare un rischio per gli utenti con disturbi convulsivi fotosensibili.',
    },
    automated: ['2.3.1'],
    expertAudit: [],
  },
  'Audio and Video': {
    name: {
      en: 'Audio & Video',
      de: 'Audio und Video',
      fr: 'Audio vidéo',
      es: 'Audio Video',
      it: 'Audio video',
    },
    description: {
      en: 'Content with dynamic media like demos, tutorials, podcasts, or interviews, reliant on visuals or audio descriptions.',
      de: 'Inhalte mit dynamischen Medien wie Demos, Tutorials, Podcasts oder Interviews, die auf visuellen oder auditiven Beschreibungen basieren.',
      fr: "Contenu avec des médias dynamiques comme des démos, des didacticiels, des podcasts ou des interviews, s'appuyant sur des descriptions visuelles ou audio.",
      es: 'Contenido con medios dinámicos como demostraciones, tutoriales, podcasts o entrevistas, que dependen de imágenes o descripciones de audio.',
      it: 'Contenuti con media dinamici come demo, tutorial, podcast o interviste, che fanno affidamento su immagini o descrizioni audio.',
    },
    automated: [],
    expertAudit: [],
  },
}
