import React from 'react'
import TextLink from '../components/common/TextLink'

const generateResultsContentTranslations = ({ userLang, scoreData }) => {
  const data = {
    en: {
      hero: {
        heading: 'Scan Results',
        heading2: `${scoreData.totalErrors} accessibility violations`,
        paragraph1: (
          <>
            Automated testing alone gives only a partial view of accessibility issues on your site. AudioEye's
            accessibility experts conduct comprehensive testing, covering all WCAG criteria missed by automation. This
            gives you a complete understanding of the{' '}
            <TextLink to="#schedule-demo" text="steps necessary to become truly compliant" />.
          </>
        ),
        paragraph2:
          "Whether you want us to take care of everything or provide you with the tools to do it yourself — we're here to help.",
        defaultCard: {
          heading: 'Your Scan Status',
          leftListItem1: 'Page Scanned',
          leftListItem2: 'Automated tests ran',
          leftListItem3: `${scoreData.totalErrors} issues found`,
          leftListItem4: 'Your next steps',
          text: 'Based on your results, we recommend finding an accessibility solution for your site.',
          ctaText: 'Watch Demo',
          ctaText2: 'Talk to expert',
        },
      },
      resultsCards: {
        heading: 'Your accessibility overview',
        shareResultsText: 'Share Results',
        shareResultsCopied: 'Copied to clipboard!',
        card1: {
          eyebrow: 'Legal risk',
          tag: 'High',
          text: `Each of the ${scoreData.totalErrors} accessibility violations on this page exposes your business to the
          risk of legal action.`,
        },
        card2: {
          eyebrow: 'Compliance',
          tag: 'Not Compliant',
          text: 'Your website is not compliant with laws like the Americans with Disabilities Act (ADA).',
        },
        card3: {
          eyebrow: 'Accessibility',
          tag: 'NOT ACCESSIBLE',
          text: 'Your website is not accessible for assistive technology users or people with disabilities.',
        },
      },
      violations: {
        cards: {
          violation: 'Violation',
          violations: 'Violations',
          progressBarHeading: 'Applicable WCAG Criteria',
          automationLabel: 'Criteria Partially Tested With Automation',
          expertLabel: 'Criteria Still Require Expert Audit',
          disabilitiesAffectedHeading: 'Disabilities Affected by Violations',
          violatedWCAGHeading: 'Violated WCAG Criteria',
          visual: 'Visual',
          cognitive: 'Cognitive',
          motor: 'Motor',
          deafBlind: 'Deaf & Blind',
          seizure: 'Seizure',
          auditory: 'Auditory',
        },
      },
      ctaCard: {
        text: 'See how AudioEye instantly fixes issues on your site',
        ctaText: 'Watch Demo',
        ctaText2: 'Talk to expert',
      },
      tabbedContentSection: {
        heading: 'Uncover more violations with Automation + Experts',
        text: 'Automated scans can identify many common accessibility violations, but some WCAG criteria cannot be reliably tested with automation alone. That’s why we offer expert human testing to help fill those gaps, providing you with a comprehensive view of your site’s accessibility.',
        tabbedContent: {
          heading: 'What Can Automated Tools Not Test?',
          tab1: {
            tabTitle: 'Dynamic Content',
            tabPanelText:
              'Automated tools cannot detect accessibility violations on page elements that are triggered by user inputs, such as error messages on input fields.',
          },
          tab2: {
            tabTitle: 'Subjective Content',
            tabPanelText:
              'Automated tools can scan for the presence of image alternative text or link descriptions — but they can’t tell if these elements are accurate or descriptive enough.',
          },
          tab3: {
            tabTitle: 'Usability Violations',
            tabPanelText:
              'Only human testers can identify certain usability issues, such as improper reading order or keyboard accessibility violations that prevent keyboard-only users from browsing a site.',
          },
        },
      },
      splitContentSection: {
        eyebrow: 'Why AudioEye',
        heading: 'Find and fix more violations with AudioEye’s complete Digital Accessibility Platform.',
        text: 'Automated scans are an effective way to measure your site’s accessibility, but some violations cannot be reliably tested by automation alone. That’s why we offer Expert Audits to supplement our industry-leading automated testing, helping customers find and fix more accessibility violations.',
        card1: {
          heading: (
            <>
              Industry-leading
              <br />
              automated testing
            </>
          ),
          text: 'Continuous monitoring and automated fixes help us deliver over 1.3 billion remediations each day.',
        },
        card2: {
          heading: (
            <>
              Expert testers,
              <br /> real-life users
            </>
          ),
          text: 'Our audits are performed by certified accessibility experts and members of the disability community.',
        },
        card3: {
          heading: (
            <>
              End-to-end
              <br /> legal support
            </>
          ),
          text: 'We provide real coverage for each page we guarantee, should a legitimate violation result in legal cost.',
        },
      },
    },
    de: {
      hero: {
        heading: 'Scanergebnisse',
        heading2: `${scoreData.totalErrors} Verstöße gegen die Barrierefreiheit`,
        paragraph1: (
          <>
            Automatisierte Tests allein geben nur einen teilweisen Überblick über Barrierefreiheitsprobleme auf Ihrer
            Website. Die Barrierefreiheitsexperten von AudioEye führen umfassende Tests durch, die alle WCAG-Kriterien
            abdecken, die durch die Automatisierung übersehen wurden. Dadurch erhalten Sie ein umfassendes Verständnis
            der <TextLink to="#schedule-demo" text="Schritte, die erforderlich sind, um wirklich konform zu werden" />.
          </>
        ),
        paragraph2:
          'Egal, ob Sie möchten, dass wir uns um alles kümmern oder Ihnen die Werkzeuge zur Verfügung stellen, mit denen Sie es selbst erledigen können – wir sind für Sie da.',
        defaultCard: {
          heading: 'Ihr Scanstatus',
          leftListItem1: 'Seite gescannt',
          leftListItem2: 'Es wurden automatisierte Tests durchgeführt',
          leftListItem3: `${scoreData.totalErrors} Probleme gefunden`,
          leftListItem4: 'Ihre nächsten Schritte',
          text: 'Basierend auf Ihren Ergebnissen empfehlen wir, eine Lösung zur Barrierefreiheit für Ihre Website zu finden.',
          ctaText: 'Demo ansehen',
          ctaText2: 'Sprechen Sie mit einem Experten',
        },
      },
      resultsCards: {
        heading: 'Ihre Übersicht zur Barrierefreiheit',
        shareResultsText: 'Ergebnisse teilen',
        shareResultsCopied: 'In die Zwischenablage kopiert!',
        card1: {
          eyebrow: 'Rechtliches Risiko',
          tag: 'Hoch',
          text: `Jedes der ${scoreData.totalErrors} Barrierefreiheitsprobleme auf dieser Seite setzt
          Ihr Unternehmen dem Risiko rechtlicher Schritte aus.`,
        },
        card2: {
          eyebrow: 'Compliance',
          tag: 'Nicht Konform',
          text: 'Ihre Website entspricht nicht Gesetzen wie dem Europäischen Gesetz zur Barrierefreiheit (EAA).',
        },
        card3: {
          eyebrow: 'Unzugänglich',
          tag: 'Unzugänglich',
          text: 'Ihre Website ist für Nutzer unterstützender Technik oder Personen mit Behinderungen nicht zugänglich.',
        },
      },
      violations: {
        cards: {
          violation: 'VERSTÖSS',
          violations: 'VERSTÖSSE',
          progressBarHeading: 'Anwendbare WCAG-Kriterien',
          automationLabel: 'Kriterien teilautomatisiert getestet',
          expertLabel: 'Kriterien erfordern noch ein Expertenaudit',
          disabilitiesAffectedHeading: 'Behinderungen, die von Verstößen betroffen sind',
          violatedWCAGHeading: 'Verstöße gegen die WCAG-Kriterien',
          visual: 'Visuell',
          cognitive: 'Kognitiv',
          motor: 'Motorisch',
          deafBlind: 'Taub & blind',
          seizure: 'Beschlagnahme',
          auditory: 'Auditiv',
        },
      },
      ctaCard: {
        text: 'Erfahren Sie anhand einer personalisierten Demo, wie AudioEye mit Hilfe automatischer und manueller Tests Barrierefreiheitsprobleme erkennt und behebt.‘',
        ctaText: 'Demo planen',
        ctaText2: 'Sprechen Sie mit einem Experten',
      },
      tabbedContentSection: {
        heading: 'Entdecken Sie weitere Verstöße mit Hilfe von Automatisierung und Experten',
        text: 'Automatisierte Scans können viele häufige Verstöße gegen die Barrierefreiheit identifizieren, einige WCAG-Kriterien können rein automatisiert jedoch nicht zuverlässig getestet werden. Aus diesem Grund stellen wir Ihnen ein Team aus menschlichen Barrierefreiheitsexperten mit Behinderungen zur Verfügung, um diese Lücken zu testen und zu ergänzen und Ihnen umfassende Informationen zur Zugänglichkeit Ihrer Website zu bieten.',
        tabbedContent: {
          heading: 'Was können automatisierte Tools nicht testen?',
          tab1: {
            tabTitle: 'Dynamischen Inhalt',
            tabPanelText:
              'Automatisierte Tools können keine Probleme auf Seitenelementen erkennen, die durch Benutzereingaben ausgelöst werden, wie Fehlermeldungen auf Eingabefeldern.',
          },
          tab2: {
            tabTitle: 'Subjektiven Inhalt',
            tabPanelText:
              'Automatisierte Tools können nach dem Vorhandensein von alternativen Bildtexten oder Link-Beschreibungen scannen, sie können jedoch nicht sagen, ob diese Elemente korrekt oder beschreibend genug sind.',
          },
          tab3: {
            tabTitle: 'Verstöße gegen die Benutzerfreundlichkeit',
            tabPanelText:
              'Nur menschliche Zugänglichkeitstester können bestimmte Usability-Probleme erkennen, wie eine falsche Lesereihenfolge oder die bei der reinen Tastaturbedienung Probleme verursachen und Benutzer, die nur die Tastatur verwenden, so daran hindern, eine Site zu durchsuchen.',
          },
        },
      },
      splitContentSection: {
        eyebrow: 'Warum AudioEye',
        heading:
          'Finden und beheben Sie weitere Probleme mit Hilfe der vollständigen digitalen Barrierefreiheitsplattform AudioEye.',
        text: 'Automatisierte Scans sind eine wirksame Methode, um die Barrierefreiheit Ihrer Site zu messen. Manche Aspekte können jedoch nicht allein durch Automatisierung zuverlässig getestet werden. Aus diesem Grund bieten wir Expertenaudits an, um unsere branchenführenden automatisierten Tests zu ergänzen und unseren Kunden dabei zu helfen, mehr Barrierefreiheitsprobleme zu finden und zu beheben.',
        card1: {
          heading: 'Branchenführende automatisierte Tests',
          text: 'Kontinuierliche Überwachung und automatisierte Fehlerbehebungen helfen uns bei der Bereitstellung von über 1,3 Milliarden Abhilfemaßnahmen pro Tag.',
        },
        card2: {
          heading: 'Expertentester',
          text: 'Unsere Audits werden von zertifizierten Barrierefreiheitsexperten und Mitgliedern der Behindertengemeinschaft durchgeführt.',
        },
        card3: {
          heading: 'Unzugänglich',
          text: 'Nutzen Sie Echtzeit-Einblicke, um Barrierefreiheitsprobleme basierend auf Benutzerauswirkungen und Compliance zu priorisieren.',
        },
      },
    },
    fr: {
      hero: {
        heading: "Résultats de l'analyse",
        heading2: `${scoreData.totalErrors} violations d'accessibilité`,
        paragraph1: (
          <>
            Les tests automatisés ne donnent à eux seuls qu’une vue partielle des problèmes d’accessibilité sur votre
            site. Les experts en accessibilité d'AudioEye effectuent des tests complets, couvrant tous les critères WCAG
            manqués par l'automatisation. Cela vous donne une compréhension complète{' '}
            <TextLink to="#schedule-demo" text="des étapes nécessaires pour devenir véritablement conforme" />.
          </>
        ),
        paragraph2:
          'Que vous souhaitiez que nous nous occupions de tout ou que vous souhaitiez que nous vous fournissions les outils nécessaires pour le faire vous-même, nous sommes là pour vous aider.',
        defaultCard: {
          heading: 'Votre statut de numérisation',
          leftListItem1: 'Page numérisée',
          leftListItem2: 'Tests automatisés exécutés',
          leftListItem3: `${scoreData.totalErrors} problèmes trouvés`,
          leftListItem4: 'Vos prochaines étapes',
          text: "Sur la base de vos résultats, nous vous recommandons de trouver une solution d'accessibilité pour votre site.",
          ctaText: 'Regarder la démo',
          ctaText2: 'Parlez à un expert',
        },
      },
      resultsCards: {
        heading: "Votre aperçu de l'accessibilité",
        shareResultsText: 'Partager les résultats',
        shareResultsCopied: 'Copié dans le presse-papier !',
        card1: {
          eyebrow: 'Risque juridique',
          tag: 'Haut',
          text: `Chacune des ${scoreData.totalErrors} violations d'accessibilité répertoriées sur cette page expose votre entreprise à des risques de poursuites judiciaires.`,
        },
        card2: {
          eyebrow: 'Conformité',
          tag: 'NON CONFORME',
          text: "Votre site Web n'est pas conforme aux lois comme européenne sur l'accessibilité (EAA).",
        },
        card3: {
          eyebrow: 'Accessibilité',
          tag: 'INACCESSIBLE',
          text: "Votre site Web est inaccessible aux utilisateurs de technologies d'assistance ou aux personnes handicapées.",
        },
      },
      violations: {
        cards: {
          violation: 'Violation',
          violations: 'Violations',
          progressBarHeading: 'Critères WCAG applicables',
          automationLabel: "Critères partiellement testés avec l'automatisation",
          expertLabel: 'Les critères nécessitent toujours un audit d’experts',
          disabilitiesAffectedHeading: 'Handicaps touchés par des violations',
          violatedWCAGHeading: 'Violations des critères WCAG',
          visual: 'Visuel',
          cognitive: 'Cognitif',
          motor: 'Moteur',
          deafBlind: 'Sourd & Aveugle',
          seizure: "Crise d'épilepsie",
          auditory: 'Auditif',
        },
      },
      ctaCard: {
        text: "Découvrez comment AudioEye utilise des tests automatiques et manuels pour identifier et résoudre les violations d'accessibilité avec une démo personnalisée.",
        ctaText: 'Planifier une démo',
        ctaText2: 'Parlez à un expert',
      },
      tabbedContentSection: {
        heading: 'Découvrez davantage de violations avec Automation + Experts',
        text: "Les analyses automatisées peuvent identifier de nombreuses violations courantes en matière d'accessibilité, mais certains critères WCAG ne peuvent pas être testés de manière fiable uniquement avec l'automatisation. C'est pourquoi nous proposons une équipe d'experts en accessibilité qui sont aussi des gens handicapés pour tester et aider à combler ces lacunes, vous offrant ainsi une vue complète de l'accessibilité de votre site.",
        tabbedContent: {
          heading: "Qu'est-ce que les outils automatisés ne peuvent pas tester ?",
          tab1: {
            tabTitle: 'Contenu dynamique',
            tabPanelText:
              "Les outils automatisés ne peuvent pas détecter les violations d'accessibilité sur les éléments de page déclenchées par les saisies de l'utilisateur, telles que les messages d'erreur sur les champs de saisie.",
          },
          tab2: {
            tabTitle: 'Contenu subjectif',
            tabPanelText:
              "Les outils automatisés peuvent rechercher la présence de texte alternatif d'image ou descriptions de liens, mais ils ne savent pas si ces éléments sont suffisamment précis ou descriptifs.",
          },
          tab3: {
            tabTitle: "Violations d'utilisabilité",
            tabPanelText:
              "Seuls les testeurs d'accessibilité humains peuvent identifier certains problèmes d'utilisabilité, tels qu'un ordre de lecture incorrect ou des violations d'accessibilité du clavier qui empêchent les utilisateurs utilisant uniquement le clavier de naviguer sur un site.",
          },
        },
      },
      splitContentSection: {
        eyebrow: 'Pourquoi AudioEye',
        heading:
          'Identifiez et corrigez davantage de violations avec la plateforme complète d’accessibilité numérique d’AudioEye.',
        text: "Les analyses automatisées sont un moyen efficace de mesurer l’accessibilité de votre site, mais certaines violations ne peuvent pas être testées de manière fiable uniquement par automatisation. C'est pourquoi nous proposons des audits d'experts pour compléter nos tests automatisés de pointe, aidant ainsi les clients à identifier et corriger davantage de violations d'accessibilité.",
        card1: {
          heading: 'Tests automatisés à la pointe du secteur',
          text: 'La surveillance continue, et les corrections automatisées nous aident à fournir plus de 1,3 milliard de corrections par jour.',
        },
        card2: {
          heading: 'Testeurs experts',
          text: 'Nos audits sont effectués par des experts certifiés en accessibilité et des membres de la communauté des personnes handicapées.',
        },
        card3: {
          heading: 'Informations en temps réel',
          text: "Utilisez des informations en temps réel pour hiérarchiser les problèmes d'accessibilité en fonction de l'impact sur les utilisateurs et la conformité.",
        },
      },
    },
    es: {
      hero: {
        heading: 'Resultados del escaneo',
        heading2: `${scoreData.totalErrors} violaciones de accesibilidad`,
        paragraph1: (
          <>
            Las pruebas automatizadas por sí solas brindan solo una visión parcial de los problemas de accesibilidad en
            su sitio. Los expertos en accesibilidad de AudioEye realizan pruebas exhaustivas que cubren todos los
            criterios WCAG que la automatización omite. Esto le brinda una comprensión completa de{' '}
            <TextLink to="#schedule-demo" text="los pasos necesarios para cumplir verdaderamente" />.
          </>
        ),
        paragraph2:
          'Ya sea que desee que nos encarguemos de todo o que le brindemos las herramientas para hacerlo usted mismo, estamos aquí para ayudarlo.',
        defaultCard: {
          heading: 'Su estado de escaneo',
          leftListItem1: 'Página escaneada',
          leftListItem2: 'Se ejecutaron pruebas automatizadas',
          leftListItem3: `${scoreData.totalErrors} problemas encontrados`,
          leftListItem4: 'Tus próximos pasos',
          text: 'Según sus resultados, le recomendamos buscar una solución de accesibilidad para su sitio.',
          ctaText: 'Programar demostración',
          ctaText2: 'Hablar con el experto',
        },
      },
      resultsCards: {
        heading: 'Su descripción general de accesibilidad',
        shareResultsText: 'Compartir resultados',
        shareResultsCopied: '¡Copiado al portapapeles!',
        card1: {
          eyebrow: 'Riesgo legal',
          tag: 'RIESGO',
          text: `Cada una de las ${scoreData.totalErrors} violaciones de accesibilidad de esta página expone a tu empresa a un riesgo de acción legal.`,
        },
        card2: {
          eyebrow: 'Cumplimiento',
          tag: 'DESOBEDIENTE',
          text: 'Tu sitio web no cumple con las leyes como la Ley de Estadounidenses con Discapacidades (ADA).',
        },
        card3: {
          eyebrow: 'Accesibilidad',
          tag: 'INACCESIBLE',
          text: 'Su sitio web no es accesible para usuarios de tecnología de asistencia ni para personas con discapacidades.',
        },
      },
      violations: {
        cards: {
          violation: 'Violación',
          violations: 'Violaciones',
          progressBarHeading: 'criterios WCAG aplicables',
          automationLabel: 'Criterios testeados parcialmente con automatización',
          expertLabel: 'Criterios que aún requieren auditoría experta',
          disabilitiesAffectedHeading: 'Discapacidades afectadas por las violaciones',
          violatedWCAGHeading: 'Criterios WCAG violados',
          visual: 'Visuales',
          cognitive: 'Cognitivas',
          motor: 'Motoras',
          deafBlind: 'Sordo y ciego',
          seizure: 'Convulsiones',
          auditory: 'Auditivo',
        },
      },
      ctaCard: {
        text: 'Descubre como AudioEye emplea testeos automáticos y manuales para identificar y resolver violaciones de accesibilidad con un demo personalizado.',
        ctaText: 'Agenda un demo',
        ctaText2: 'Hablar con el experto',
      },
      tabbedContentSection: {
        heading: 'Descubre más violaciones con automatizaciones + expertos.',
        text: 'Los escaneos automáticos pueden identificar muchas violaciones de accesibilidad, pero algunos criterios WCAG no pueden testearse de manera confiable únicamente con automatización. Por ello, ofrecemos un equipo de expertos en accesibilidad con discapacidad para realizar testeos y ayudar a cubrir esas brechas, brindándote una visión integral de la accesibilidad de tu sitio.',
        tabbedContent: {
          heading: '¿Qué no se puede comprobar mediante herramientas automatizadas?',
          tab1: {
            tabTitle: 'Contenido dinámico',
            tabPanelText:
              'Las herramientas automatizadas no pueden detectar violaciones de accesibilidad en elementos de la página que se activan por entradas del usuario, como mensajes de error en campos de entrada.',
          },
          tab2: {
            tabTitle: 'Contenido subjetivo',
            tabPanelText:
              'Las herramientas automatizadas pueden escanear la presencia de texto alternativo en las imágenes o descripciones de enlaces, pero no pueden determinar si estos elementos son suficientemente precisos o descriptivos.',
          },
          tab3: {
            tabTitle: 'Violaciones de usabilidad',
            tabPanelText:
              'Solo testeadores humanos de accesibilidad pueden identificar ciertos problemas de usabilidad, como, un orden de lectura incorrecto o violaciones de accesibilidad de teclado que impiden navegar por un sitio a los usuarios que únicamente utilizan el teclado.',
          },
        },
      },
      splitContentSection: {
        eyebrow: 'Por qué AudioEye',
        heading: 'Encuentra y soluciona más violaciones con la plataforma de accesibilidad digital completa, AudioEye.',
        text: 'Los escaneos automatizados son una manera efectiva de medir la accesibilidad de tu sitio, pero algunas violaciones no pueden testearse de manera confiable únicamente con la automatización. Por ello, ofrecemos auditorías de expertos que complementan nuestros testeos automatizados, ayudando a los clientes a encontrar y solucionar más violaciones de accesibilidad.',
        card1: {
          heading: (
            <>
              Los testeos automatizados
              <br />
              que lideran la industria
            </>
          ),
          text: 'El monitoreo continuo y las correcciones automatizadas nos permiten realizar más de 1,300 millones de correcciones diarias.',
        },
        card2: {
          heading: (
            <>
              Testeadores expertos,
              <br /> Usuarios de la vida real
            </>
          ),
          text: 'Nuestras auditorías son realizadas por expertos certificados en accesibilidad y miembros de la comunidad de discapacitados.',
        },
        card3: {
          heading: (
            <>
              Soporte legal
              <br /> de extremo a extremo
            </>
          ),
          text: 'Brindamos cobertura real para cada página que garantizamos, en caso de que una violación legítima resulte en costos legales.',
        },
      },
    },
    it: {
      hero: {
        heading: 'Risultati della scansione',
        heading2: `${scoreData.totalErrors} violazioni dell'accessibilità`,
        paragraph1: (
          <>
            Il solo test automatizzato fornisce solo una visione parziale dei problemi di accessibilità sul tuo sito.
            Gli esperti di accessibilità di AudioEye conducono test approfonditi, coprendo tutti i criteri WCAG non
            rispettati dall'automazione. Ciò ti offre una comprensione completa{' '}
            <TextLink to="#schedule-demo" text="dei passaggi necessari per diventare veramente conforme" />.
          </>
        ),
        paragraph2:
          'Sia che tu voglia che ci occupiamo di tutto o che ti forniamo gli strumenti per farlo da solo, siamo qui per aiutarti.',
        defaultCard: {
          heading: 'Il tuo stato di scansione',
          leftListItem1: 'Pagina scansionata',
          leftListItem2: 'Sono stati eseguiti test automatizzati',
          leftListItem3: `${scoreData.totalErrors} problemi trovati`,
          leftListItem4: 'I tuoi prossimi passi',
          text: 'In base ai risultati, ti consigliamo di trovare una soluzione di accessibilità per il tuo sito.',
          ctaText: 'Pianifica demo',
          ctaText2: 'Parla con un esperto',
        },
      },
      resultsCards: {
        heading: "La tua panoramica sull'accessibilità",
        shareResultsText: 'Condividi i risultati',
        shareResultsCopied: 'Copiato negli appunti!',
        card1: {
          eyebrow: 'Rischio legale',
          tag: 'RISCHIO',
          text: `Ognuna delle ${scoreData.totalErrors} violazioni dell'accessibilità presenti in questa pagina espone la tua azienda al rischio di azioni legali.`,
        },
        card2: {
          eyebrow: 'Conformità',
          tag: 'NON CONFORME',
          text: "Il tuo sito web non è conforme alle leggi come l'American with Disabilities Act (ADA).",
        },
        card3: {
          eyebrow: 'Accessibilità',
          tag: 'INACCESSIBILE',
          text: 'Il tuo sito web non è accessibile agli utenti di tecnologie assistive o alle persone con disabilità.',
        },
      },
      violations: {
        cards: {
          violation: 'Violazione',
          violations: 'Violazioni',
          progressBarHeading: 'Criteri WCAG applicabili',
          automationLabel: "Criteri parzialmente testati con l'automazione",
          expertLabel: 'Dei criteri richiedono ancora una verifica da parte di esperti',
          disabilitiesAffectedHeading: 'Disabilità colpite da violazioni',
          violatedWCAGHeading: 'Criteri WCAG violati',
          visual: 'Visuali',
          cognitive: 'Cognitive',
          motor: 'Motorie',
          deafBlind: 'Sordo cieco',
          seizure: 'attacchi epilettici',
          auditory: 'Uditivo',
        },
      },
      ctaCard: {
        text: "Scopri come AudioEye utilizza test automatici e manuali per identificare e risolvere le violazioni dell'accessibilità con una demo personalizzata.",
        ctaText: 'Programma una demo',
        ctaText2: 'Parla con un esperto',
      },
      tabbedContentSection: {
        heading: 'Scopri altre violazioni con Automazione + Esperti',
        text: "Le scansioni automatizzate possono identificare molte violazioni comuni dell'accessibilità, ma alcuni criteri WCAG non possono essere testati in modo affidabile con la sola automazione. Ecco perché mettiamo a disposizione un team di persone con disabilità esperte di accessibilità per testare e aiutare a colmare queste lacune, fornendovi una visione completa dell'accessibilità del vostro sito.",
        tabbedContent: {
          heading: 'Cosa non può esere testato dagli strumenti automatici?',
          tab1: {
            tabTitle: 'Contenuti dinamici',
            tabPanelText:
              "Gli strumenti automatizzati non sono in grado di rilevare le violazioni dell'accessibilità degli elementi della pagina causate dagli input dell'utente, come i messaggi di errore nei campi di input.",
          },
          tab2: {
            tabTitle: 'Contenuto soggettivo',
            tabPanelText:
              'Gli strumenti automatizzati possono cercare immagini alternative testuali o descrizioni di link, ma non sono in grado di dire se questi elementi sono sufficientemente precisi o descrittivi.',
          },
          tab3: {
            tabTitle: 'Violazioni di usabilità',
            tabPanelText:
              "Solo i tester dell'accessibilità umana sono in grado di individuare determinati problemi di usabilità, come l'ordine di lettura improprio o le violazioni dell'accessibilità della tastiera che impediscono agli utenti che utilizzano solo la tastiera di navigare in un sito.",
          },
        },
      },
      splitContentSection: {
        eyebrow: 'Perché AudioEye',
        heading:
          'Individua e correggi più violazioni con la piattaforma di accessibilità digitale completa di AudioEye.',
        text: "Le scansioni automatizzate sono un modo efficace per misurare l'accessibilità del sito, ma alcune violazioni non possono essere verificate in modo affidabile solo dall'automazione. Ecco perché offriamo verifiche da parte di esperti per integrare i nostri test automatizzati leader del settore, aiutando i clienti a individuare e correggere un maggior numero di violazioni dell'accessibilità.",
        card1: {
          heading: (
            <>
              Test automatizzati
              <br />
              leader del settore
            </>
          ),
          text: 'Il monitoraggio continuo e le correzioni automatizzate ci aiutano a fornire oltre 1,3 miliardi di correzioni ogni giorno.',
        },
        card2: {
          heading: (
            <>
              Esaminatori esperti,
              <br /> utenti reali
            </>
          ),
          text: 'Le nostre verifiche sono effettuate da esperti certificati di accessibilità e membri della comunità disabile.',
        },
        card3: {
          heading: (
            <>
              Supporto legale
              <br /> end-to-end
            </>
          ),
          text: 'Forniamo una copertura reale per ogni pagina che garantiamo, nel caso in cui una violazione legittima comporti costi legali.',
        },
      },
    },
  }

  return data[userLang] || data.en
}

export default generateResultsContentTranslations
