import { trackEvent } from '../eventTrack'

// site grader request - if successful, should return the uuid for this URL from site grader + a 200
export const getUrlId = async (scanUrl, updateStatus, setErrorMsg, variant) => {
  const response = await fetch(`/api/fetch-id?url=${scanUrl}`)
  if ((await response.status) < 200 || (await response.status) > 299) {
    if ((await response.status) === 503 || (await response.status) === 502) {
      setErrorMsg(`general-issue`)
      trackEvent(`Site Scan Failed`, {
        'Site URL': scanUrl,
        'Site Scan Invoked By': variant,
        'Job Status': 'scan service unresponsive',
      })
    } else {
      setErrorMsg(`valid-website`)
    }
    return false
  }

  // resJson.id is the uuid returned by Site Grader
  const resJson = await response.json()

  if (resJson && resJson.id) {
    updateStatus({ running: true })
    return resJson.id
  }

  return false
}

// Uses the uuid generated by Site Grader to fetch scan score data
export const fetchUrlScore = async (
  uuid,
  setErrorMsg,
  variant,
  updateStatus,
  setScoreData,
  status,
  setDataLoaded,
  updateFormData,
  partner,
  lang,
  mktg,
  scanUrl,
  onShowForm
) => {
  // hit site grader /score/{uuid}?results={num top issues returned} endpoint
  const response = await fetch(`/api/fetch-results?uuid=${uuid}`)
  if (response.status === 503) {
    setErrorMsg(`general-issue`)
    trackEvent(`Site Scan Failed`, {
      'Site URL': scanUrl,
      'Site Scan Invoked By': variant,
      'Job Status': 'scan service unresponsive',
    })
    return false
  }

  let data
  const resJson = await response.json()

  // Update lifecycle and score data per request
  if (resJson.scanState) {
    data = resJson
  }
  // set internal scan status variable
  if (data) {
    updateStatus((status[data.scanState] = true))
  }

  // if scan fails
  if (data && data.scanState === 'failed') {
    setErrorMsg('failed')
    trackEvent(`Site Scan Failed`, {
      'Site URL': scanUrl,
      'Site Scan Invoked By': variant,
      'Job Status': data.scanState,
      'Failed Reason': data.failedReason,
    })
    return false
  }

  // if scan completed successfully
  if (data && data.scanState === 'completed') {
    // Once score data is available, update state with data and lifecycle status
    if (data.tests) {
      let totalErrors = 0
      let categoryData = []
      const totalWCAGCriteria = []
      const testsWithFailures = []

      data.tests.forEach(currentTest => {
        if (!categoryData.some(obj => obj.name === currentTest.ruleMetadata.category)) {
          categoryData.push({
            name: currentTest.ruleMetadata.category,
            totalFail: 0,
            disabilitiesAffected: [],
            wcagCriteriaAffected: [],
          })
        }

        if (currentTest.ruleMetadata.contributesToScore) {
          const currentNumberOfFails = parseInt(currentTest.fail, 10)
          const currentCategory = categoryData.find(obj => obj.name === currentTest.ruleMetadata.category)

          totalErrors += currentNumberOfFails
          currentCategory.totalFail += currentNumberOfFails

          currentTest.ruleMetadata.standards.forEach(currentStandard => {
            if (currentStandard.type === 'WCAG') {
              if (!totalWCAGCriteria.includes(currentStandard.criteria)) {
                totalWCAGCriteria.push(currentStandard.criteria)
              }
            }
          })

          if (currentNumberOfFails > 0) {
            testsWithFailures.push(currentTest)

            if (!currentCategory.disabilitiesAffected.includes(currentTest.ruleMetadata.primaryBeneficiary)) {
              currentCategory.disabilitiesAffected.push(currentTest.ruleMetadata.primaryBeneficiary)
            }

            currentTest.ruleMetadata.standards.forEach(currentStandard => {
              if (currentStandard.type === 'WCAG') {
                if (!currentCategory.wcagCriteriaAffected.some(obj => obj.criteria === currentStandard.criteria)) {
                  currentCategory.wcagCriteriaAffected.push(currentStandard)
                }
              }
            })
          }
        }
      })

      categoryData = categoryData.sort((a, b) => b.totalFail - a.totalFail)
      testsWithFailures.sort((a, b) => b.fail - a.fail)

      const topThree = []
      const remainingCategories = categoryData
      let remainingCategoriesTotalErrors = 0

      for (let i = 0; i < 3; i += 1) {
        topThree.push(categoryData[i])
      }

      for (let i = 0; i < topThree.length; i += 1) {
        remainingCategories.shift()
      }

      remainingCategories.forEach(currentRemainingCategory => {
        remainingCategoriesTotalErrors += currentRemainingCategory.totalFail
      })

      setScoreData({
        ...data,
        totalErrors,
        topThree,
        categoryData,
        remainingCategories,
        remainingCategoriesTotalErrors,
        testsWithFailures,
        totalWCAGCriteria: parseInt(data.reportingVersion.charAt(0), 10) >= 7 ? totalWCAGCriteria.length : 20,
        loaded: true,
      })
    }

    trackEvent(`Site Scan Completed`, {
      'Site URL': data.url,
      'Site Scan Invoked By': variant,
    })

    onShowForm()
    setDataLoaded(true)

    const urlParams = new URLSearchParams(window.location.search)
    const mutinyPreview = urlParams.has('mutiny_preview') ? `&mutiny_preview=${urlParams.get('mutiny_preview')}` : null
    const baseUrl = `/scan/results/?uuid=${uuid}&&url=${scanUrl}&partner=${partner}&mktg=${mktg}&lang=${lang}`
    const fullUrl = mutinyPreview ? `${baseUrl}${mutinyPreview}` : baseUrl

    updateFormData({
      scannerScoreUrl: `https://www.audioeye.com${fullUrl}`,
      baseScannerUrl: fullUrl,
    })

    if (window.sessionStorage.getItem('gate') === 'false' || partner || mktg) {
      window.history.pushState({}, '', fullUrl)
    }
  }
}
