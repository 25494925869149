import React from 'react'
import styled from '@emotion/styled'

const Heading2PinkText = styled.span`
  color: ${props => props.theme.color.pink100} !important;
  font-size: ${props => props.theme.fonts.size.heading2}!important;
  font-family: ${props => props.theme.fonts.family.heading2}!important;
  font-weight: ${props => props.theme.fonts.weight.heading2}!important;
  letter-spacing: ${props => props.theme.fonts.letterSpacing.heading2}!important;
  line-height: ${props => props.theme.fonts.lineHeight.heading2}!important;
  ${props =>
    props.lang !== 'en' &&
    `
  word-break: break-word;
  hyphens: auto;
`}
`

const Heading4PinkText = styled.span`
  color: ${props => props.theme.color.pink100} !important;
  font-size: ${props => props.theme.fonts.size.heading4}!important;
  font-family: ${props => props.theme.fonts.family.heading4}!important;
  font-weight: ${props => props.theme.fonts.weight.heading4}!important;
  line-height: ${props => props.theme.fonts.lineHeight.heading4}!important;
`

const generateScannerProgressTranslations = ({ userLang, totalErrors }) => {
  const data = {
    en: {
      scanning: {
        eyebrow: 'Scanning Website',
        carouselMessages: {
          one: 'Scanning Page',
          two: 'Running WCAG Tests',
          three: 'Detecting Issues',
          stillProcessing: 'Scan still processing. Thank you for your patience!',
        },
      },
      leftContent: {
        errorContent: {
          validWebsite: {
            eyebrow: 'Scan Incomplete',
            message: (
              <>
                It looks like the <Heading4PinkText>URL you entered was invalid</Heading4PinkText>, please enter your
                URL to re-scan your site.
              </>
            ),
          },
          existingCustomer: {
            eyebrow: 'Scan Incomplete',
            message: (
              <>
                It looks like <Heading4PinkText>you already have an AudioEye account</Heading4PinkText>! Please log into
                the dashboard or reach out to your website provider.
              </>
            ),
          },
          partner: {
            eyebrow: 'Scan Incomplete',
            message: (
              <>
                We are <Heading4PinkText>unable to scan your site</Heading4PinkText> at this time. Try re-scanning or
                reach out to your AudioEye Account Manager.
              </>
            ),
            secondMessage: (
              <>
                Note: If your website provider is an AudioEye partner, please reach out to the partner to get started.
              </>
            ),
          },
          default: {
            eyebrow: 'Scan Incomplete',
            message: (
              <>
                <Heading4PinkText>We weren't able to scan your URL at this time,</Heading4PinkText> but you can start a
                free trial and install AudioEye to get a full report of your site's accessibility.
              </>
            ),
            secondMessage: (
              <>
                Note: If your website provider is an AudioEye partner, please reach out to the partner to get started.
              </>
            ),
          },
        },
      },
      rightContent: {
        errorContent: {
          validWebsite: {
            eyebrow: 'Please re-scan your site',
            scannerFormLabelText: 'Enter Website URL',
            scannerFormButtonText: 'Free Scan',
            scannerFormPlaceholderText: 'www.yourwebsite.com',
          },
          existingCustomer: {
            eyebrow: 'Welcome back!',
            buttonText: 'Dashboard Login',
          },
        },
      },
      scanComplete: {
        eyebrow: 'Scan Complete',
        heading: (
          <>
            We found <Heading2PinkText lang={userLang}>{totalErrors} accessibility violations</Heading2PinkText> on your
            page.
          </>
        ),
        callout:
          'Web Content Accessibility Guidelines (WCAG) are a set of standards and instructions on making digital content accessible to people with disabilities. Think of these violations as accessibility issues on your site.',
        form: {
          heading: 'See your full scan results',
        },
      },
    },
    de: {
      scanning: {
        eyebrow: 'Scannen der Website',
        carouselMessages: {
          one: 'Scannen der Seite',
          two: 'Ausführen von WCAG-Tests',
          three: 'Erkennen von Barrieren',
          stillProcessing: 'Scan läuft noch. Vielen Dank für Ihre Geduld!',
        },
      },
      leftContent: {
        errorContent: {
          validWebsite: {
            eyebrow: 'SCAN UNVOLLSTÄNDIG',
            message: (
              <>
                Es sieht so aus, als ob die von Ihnen eingegebene <Heading4PinkText>URL ungültig war</Heading4PinkText>.
                Bitte geben Sie Ihren URL ein, um Ihre Website erneut zu scannen.
              </>
            ),
          },
          existingCustomer: {
            eyebrow: 'SCAN UNVOLLSTÄNDIG',
            message: (
              <>
                Es sieht so aus, als hätten <Heading4PinkText>Sie bereits ein AudioEye-Konto!</Heading4PinkText> Bitte
                melden Sie sich im Dashboard an oder wenden Sie sich an Ihren Website-Anbieter.
              </>
            ),
          },
          partner: {
            eyebrow: 'SCAN UNVOLLSTÄNDIG',
            message: (
              <>
                <Heading4PinkText>Wir konnten Ihre URL zu diesem Zeitpunkt nicht scannen</Heading4PinkText>, aber Sie
                können eine kostenlose Testphase starten und AudioEye installieren, um einen vollständigen Bericht über
                die Barrierefreiheit Ihrer Website zu erhalten.
              </>
            ),
            secondMessage: (
              <>
                Hinweis: Ist Ihr Website-Anbieter AudioEye-Partner, kontaktieren Sie bitte den Partner, um zu beginnen.
              </>
            ),
          },
          default: {
            eyebrow: 'SCAN UNVOLLSTÄNDIG',
            message: (
              <>
                <Heading4PinkText>Wir konnten Ihre URL zu diesem Zeitpunkt nicht scannen</Heading4PinkText>, aber Sie
                können eine kostenlose Testphase starten und AudioEye installieren, um einen vollständigen Bericht über
                die Barrierefreiheit Ihrer Website zu erhalten.
              </>
            ),
            secondMessage: (
              <>
                Hinweis: Ist Ihr Website-Anbieter AudioEye-Partner, kontaktieren Sie bitte den Partner, um zu beginnen.
              </>
            ),
          },
        },
      },
      rightContent: {
        errorContent: {
          validWebsite: {
            eyebrow: 'Bitte scannen Sie Ihre Website erneut',
            scannerFormLabelText: 'Website-URL eingeben',
            scannerFormButtonText: 'Site scannen',
            scannerFormPlaceholderText: 'www.yourwebsite.com',
          },
          existingCustomer: {
            eyebrow: 'Willkommen zurück!',
            buttonText: 'Dashboard-Login',
          },
        },
      },
      scanComplete: {
        eyebrow: 'Scan abgeschlossen',
        heading: (
          <>
            Wir haben auf Ihrer Seite{' '}
            <Heading2PinkText lang={userLang}>{totalErrors} Barrierefreiheitsprobleme</Heading2PinkText> festgestellt
          </>
        ),
        callout:
          'Web-Content-Barrierefreiheitsrichtlinien (WCAG) sind eine globale Reihe von Standards und Anweisungen, um Menschen mit Behinderungen digitale Inhalte zugänglich zu machen. Stellen Sie sich diese Probleme als Einschränkungen der Barrierefreiheit auf Ihrer Website vor.',
        form: {
          heading: 'Vollständige Scan-Ergebnisse ansehen',
        },
      },
    },
    fr: {
      scanning: {
        eyebrow: 'Analyser le site Web',
        carouselMessages: {
          one: 'Analyser la page',
          two: 'Effectuer des tests WCAG',
          three: 'Détection des violations',
          stillProcessing: 'Analyse en cours. Merci pour votre patience !',
        },
      },
      leftContent: {
        errorContent: {
          validWebsite: {
            eyebrow: 'ANALYSE INCOMPLET',
            message: (
              <>
                Il semble que <Heading4PinkText>l'URL vous avez saisie n'est pas valide</Heading4PinkText>. Veuillez
                saisir votre URL pour analyser à nouveau votre site.
              </>
            ),
          },
          existingCustomer: {
            eyebrow: 'ANALYSE INCOMPLET',
            message: (
              <>
                Il semble que <Heading4PinkText>vous ayez déjà un compte AudioEye !</Heading4PinkText> Veuillez
                connecter au tableau de bord ou contacter votre fournisseur de site Web.
              </>
            ),
          },
          partner: {
            eyebrow: 'ANALYSE INCOMPLET',
            message: (
              <>
                <Heading4PinkText>Nous n'avons pas pu analyser votre URL pour le moment</Heading4PinkText>, mais vous
                pouvez commencer un essai gratuit et installer AudioEye pour obtenir un rapport complet sur
                l'accessibilité de votre site.
              </>
            ),
            secondMessage: (
              <>
                Note : Si votre fournisseur de site Web est un partenaire AudioEye, veuillez contacter le partenaire
                pour démarrer le processus.
              </>
            ),
          },
          default: {
            eyebrow: 'ANALYSE INCOMPLET',
            message: (
              <>
                <Heading4PinkText>Nous n'avons pas pu analyser votre URL pour le moment</Heading4PinkText>, mais vous
                pouvez commencer un essai gratuit et installer AudioEye pour obtenir un rapport complet sur
                l'accessibilité de votre site.
              </>
            ),
            secondMessage: (
              <>
                Note : Si votre fournisseur de site Web est un partenaire AudioEye, veuillez contacter le partenaire
                pour démarrer le processus.
              </>
            ),
          },
        },
      },
      rightContent: {
        errorContent: {
          validWebsite: {
            eyebrow: 'Veuillez réanalyser votre site',
            scannerFormLabelText: "Saisissez l'URL du site Web",
            scannerFormButtonText: 'Analyser le site',
            scannerFormPlaceholderText: 'www.yourwebsite.com',
          },
          existingCustomer: {
            eyebrow: 'Content de vous revoir !',
            buttonText: 'Connexion au tableau de bord',
          },
        },
      },
      scanComplete: {
        eyebrow: 'Analyse terminé',
        heading: (
          <>
            Nous avons trouvé{' '}
            <Heading2PinkText lang={userLang}>{totalErrors} violations d'accessibilité</Heading2PinkText> sur votre page
          </>
        ),
        callout:
          "Les directives pour l'accessibilité du contenu Web (WCAG) sont un ensemble de normes et d'instructions mondial visant à rendre le contenu numérique accessible aux personnes handicapées. Considérez ces violations comme des problèmes d'accessibilité sur votre site.",
        form: {
          heading: "Consultez les résultats complets de l'analyse",
        },
      },
    },
    es: {
      scanning: {
        eyebrow: 'Escaneando sitio web',
        carouselMessages: {
          one: 'Escaneando página',
          two: 'Ejecutando pruebas WCAG',
          three: 'Detectando violaciones',
          stillProcessing: 'El escaneo sigue en proceso. ¡Gracias por tu paciencia!',
        },
      },
      leftContent: {
        errorContent: {
          validWebsite: {
            eyebrow: 'ESCANEO INCOMPLETO',
            message: (
              <>
                Parece que <Heading4PinkText>la URL que ingresaste es inválida</Heading4PinkText>, ingresa tu URL
                nuevamente para volver a escanear el sitio.
              </>
            ),
          },
          existingCustomer: {
            eyebrow: 'ESCANEO INCOMPLETO',
            message: (
              <>
                ¡Parece que <Heading4PinkText>ya tienes una cuenta de AudioEye</Heading4PinkText>! Accede al panel de
                control o comunícate con tu proveedor de sitios web.
              </>
            ),
          },
          partner: {
            eyebrow: 'ESCANEO INCOMPLETO',
            message: (
              <>
                No <Heading4PinkText>podemos escanear su sitio</Heading4PinkText> en este momento. Intente volver a
                escanear o comuníquese con su administrador de cuentas de AudioEye
              </>
            ),
            secondMessage: (
              <>Nota: Si tu proveedor de sitios web es socio de AudioEye, comunícate con él para poder comenzar.</>
            ),
          },
          default: {
            eyebrow: 'ESCANEO INCOMPLETO',
            message: (
              <>
                <Heading4PinkText>No pudimos escanear tu URL en este momento,</Heading4PinkText> pero puedes comenzar
                una prueba gratuita e instalar AudioEye para obtener un informe completo de accesibilidad de tu sitio.
              </>
            ),
            secondMessage: (
              <>Nota: Si tu proveedor de sitios web es socio de AudioEye, comunícate con él para poder comenzar.</>
            ),
          },
        },
      },
      rightContent: {
        errorContent: {
          validWebsite: {
            eyebrow: 'Por favor vuelve a escanear tu sitio',
            scannerFormLabelText: 'Ingresa la URL del sitio web',
            scannerFormButtonText: 'Escaneo gratuito',
            scannerFormPlaceholderText: 'www.yourwebsite.com',
          },
          existingCustomer: {
            eyebrow: '¡Bienvenido de vuelta!',
            buttonText: 'Inicio de sesión del panel de control',
          },
        },
      },
      scanComplete: {
        eyebrow: 'Escaneo Completo',
        heading: (
          <>
            Encontramos <Heading2PinkText lang={userLang}>{totalErrors} violaciones de accesibilidad</Heading2PinkText>{' '}
            en tu página.
          </>
        ),
        callout:
          'Las Directrices de Accesibilidad al Contenido Web (WCAG, por sus siglas en inglés) son un conjunto global de estándares e instrucciones sobre cómo hacer accesible el contenido digital para personas con discapacidad. Piensa en estas violaciones como problemas de accesibilidad en tu sitio.',
        form: {
          heading: 'Vea los resultados completos del análisis',
        },
      },
    },
    it: {
      scanning: {
        eyebrow: 'Scansione sito web',
        carouselMessages: {
          one: 'Scansione della pagina',
          two: 'Esecuzione dei test WCAG',
          three: 'Rilevamento delle violazioni',
          stillProcessing: 'La scansione è ancora in corso. Grazie per la pazienza!',
        },
      },
      leftContent: {
        errorContent: {
          validWebsite: {
            eyebrow: 'SCANSIONE NON COMPLETATA',
            message: (
              <>
                Sembra che <Heading4PinkText>l'URL inserito non fosse valido</Heading4PinkText>, inserisci il tuo URL
                per ri-scansionare il tuo sito.
              </>
            ),
          },
          existingCustomer: {
            eyebrow: 'SCANSIONE NON COMPLETATA',
            message: (
              <>
                Sembra che <Heading4PinkText>tu abbia già un account AudioEye</Heading4PinkText>! Accedi alla dashboard
                o contatta il provider del tuo sito web.
              </>
            ),
          },
          partner: {
            eyebrow: 'SCANSIONE NON COMPLETATA',
            message: (
              <>
                Al momento <Heading4PinkText>non siamo in grado di scansionare il tuo sito</Heading4PinkText>. Prova a
                ripetere la scansione o contatta il tuo account manager AudioEye
              </>
            ),
            secondMessage: (
              <>Nota: Se il tuo fornitore di siti web è un partner AudioEye, contatta il partner per iniziare.</>
            ),
          },
          default: {
            eyebrow: 'SCANSIONE NON COMPLETATA',
            message: (
              <>
                <Heading4PinkText>Al momento non siamo stati in grado di scansionare l'URL,</Heading4PinkText> ma è
                possibile avviare una prova gratuita e installare AudioEye per ottenere un rapporto completo
                sull'accessibilità del sito.
              </>
            ),
            secondMessage: (
              <>Nota: Se il tuo fornitore di siti web è un partner AudioEye, contatta il partner per iniziare.</>
            ),
          },
        },
      },
      rightContent: {
        errorContent: {
          validWebsite: {
            eyebrow: 'Si prega di ripetere la scansione del sito',
            scannerFormLabelText: "Inserire l'URL del sito",
            scannerFormButtonText: 'Scansione gratuita',
            scannerFormPlaceholderText: 'www.yourwebsite.com',
          },
          existingCustomer: {
            eyebrow: 'Bentornato/a!',
            buttonText: 'Dashboard Login',
          },
        },
      },
      scanComplete: {
        eyebrow: 'Scansione completa',
        heading: (
          <>
            Abbiamo trovato{' '}
            <Heading2PinkText lang={userLang}>{totalErrors} Violazioni di accessibilità</Heading2PinkText> sulla tua
            pagina.
          </>
        ),
        callout:
          "Le linee guida per l'accessibilità dei contenuti web (WCAG) sono un insieme globale di norme e istruzioni per rendere i contenuti digitali accessibili alle persone con disabilità. Pensa a queste violazioni come problemi di accessibilità sul tuo sito.",
        form: {
          heading: 'Visualizza i risultati completi della scansione',
        },
      },
    },
  }

  return data[userLang] || data.en
}

export default generateScannerProgressTranslations
